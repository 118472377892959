<template>
  <!--  右键菜单  -->
  <div
    class="xpp-context-menu"
    v-if="dialogElementShow.paperContext"
    :style="paperContextMenuPosition"
  >
    <ul @click="paperContextMenuEvent">
      <li
        class="xpp-context-menu-item"
        v-for="menu in paperContextMenuOption"
        :key="menu.type"
        :class="{ 'context-menu-separator': menu.separator }"
        :data-type="menu.type"
      >
        <i class="iconfont" :class="menu.icon"></i><span>{{ menu.text }}</span>
        <template v-if="menu.child">
          <ul class="xpp-context-select" :class="menu.selectClass">
            <li
              class="xpp-context-select-item"
              v-for="item in menu.child"
              :key="item.value"
              :data-value="item.value"
              :data-type="menu.type"
            ></li>
          </ul>
        </template>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import { fileTypeVail } from "@plugins/fileUpload";

export default {
  name: "PaperContextMenu",
  computed: {
    paperContextMenuPosition() {
      let style = {};
      let { top, left } = this.pagePositionInfo;
      let viewHeight = document.documentElement.clientHeight;
      let viewWidth = document.documentElement.clientWidth - 6;
      let height = this.paperContextMenuOption.length * 42 + 8;
      let width = 242;
      if (top + height > viewHeight) {
        top -= top + height - viewHeight + 10;
      }
      if (left + width > viewWidth) {
        left -= left + width - viewWidth;
      }
      if (top < 0) {
        top = 6;
      }
      style.top = top + "px";
      style.left = left + "px";
      return style;
    },
    paperContextMenuOption() {
      let event = this.paperActiveDom;
      if (this.paperOperateMode === 3) {
        switch (event.type) {
          case void 0:
            this.$DiaLoginFoUpDate({
              key: "paperContext",
              value: false
            });
            return [];
          default:
            return [
              {
                type: "comment",
                text: "添加评论",
                icon: "icon-comment"
              }
            ];
        }
      }
      let list = null;
      let {
        previewImg,
        previewFile,
        download,
        editCon,
        editTitle,
        checkBg,
        checkImage,
        share,
        newPaper,
        openPaper,
        backup,
        currency,
        editOperate
      } = this.paperContextMenuList;
      if (event instanceof Array) {
        return [
          {
            type: "lock",
            text: "锁定",
            icon: "icon-lock",
            separator: true
          },
          ...editOperate
        ];
      } else {
        currency[3].text = event.lock ? "解锁" : "锁定";

        switch (event.type) {
          case "noteSequence":
            list = [editCon, checkBg, backup, ...currency, ...editOperate];
            break;
          case "textSequence":
            list = [editCon, backup, ...currency, ...editOperate];
            break;
          case "svgSequence":
            list = [...editOperate];
            break;
          case "paperSequence":
            list = [
              editTitle,
              share,
              checkImage,
              openPaper,
              ...currency,
              ...editOperate
            ];
            break;
          case "imagesSequence":
            list = [previewImg, download, ...currency, ...editOperate];
            break;
          case "filesSequence":
            list = [download, ...currency, ...editOperate];
            let file = document
              .getElementById(event.id)
              .getElementsByClassName("xpp-paper-files-type")[0].dataset.file;
            if (fileTypeVail(file)) {
              list.unshift(previewFile);
            }
            break;
          case "hyperlinkSequence":
            list = [editTitle, checkImage, ...currency, ...editOperate];
            break;
          default:
            list = [
              {
                type: "paste",
                text: "粘贴",
                icon: "icon-paste"
              },
              newPaper
            ];
            let revoke = this.xppOperationRecord[0][0];
            let recovery = this.xppOperationRecord[1][0];
            if (recovery) {
              list.unshift({
                type: "recovery",
                text: "恢复",
                icon: "icon-undo icon-mirror"
              });
            }
            if (revoke) {
              list.unshift({
                type: "revoke",
                text: "撤销",
                icon: "icon-undo"
              });
            }
            break;
        }
      }

      return list;
    },
    ...mapGetters([
      "dialogElementShow",
      "paperActiveDom",
      "pagePositionInfo",
      "paperOperateMode",
      "xppOperationRecord"
    ])
  },
  data() {
    return {
      paperContextMenuList: {
        previewImg: {
          type: "open_image",
          text: "预览",
          icon: "icon-sousuo"
        },
        previewFile: {
          type: "open_file",
          text: "预览",
          icon: "icon-sousuo"
        },
        download: {
          type: "down_load",
          text: "下载",
          icon: "icon-download",
          separator: true
        },
        editCon: {
          type: "edit-con",
          text: "编辑",
          icon: "icon-edit"
        },
        editTitle: {
          type: "edit-tit",
          text: "更改名称",
          icon: "icon-edit"
        },
        checkBg: {
          type: "check-bgcolor",
          text: "更换颜色",
          icon: "icon-paint-line",
          separator: true,
          selectClass: "xpp-menu-select-color",
          child: [
            {
              value: "#fff393"
            },
            {
              value: "#ffd342"
            },
            {
              value: "#ff7d21"
            },
            {
              value: "#ff59b6"
            },
            {
              value: "#f0ff7e"
            },
            {
              value: "#d9ef34"
            },
            {
              value: "#36edc4"
            },
            {
              value: "#009077"
            },
            {
              value: "#ccf6ff"
            },
            {
              value: "#a9e4ff"
            },
            {
              value: "#51b1ff"
            },
            {
              value: "#2971ff"
            },
            {
              value: "#e9f1ff"
            },
            {
              value: "#d9e1ef"
            },
            {
              value: "#4E4E4E"
            },
            {
              value: "#333333"
            },
            {
              value: "transparent"
            }
          ]
        },
        checkImage: {
          type: "check-image",
          text: "上传缩略图",
          icon: "icon-image",
          separator: true
        },
        share: {
          type: "share",
          text: "分享",
          icon: "icon-share"
        },
        newPaper: {
          type: "new_paper",
          text: "新Paper",
          icon: "icon-open_folder"
        },
        openPaper: {
          type: "open_folder",
          text: "打开Paper",
          icon: "icon-open_folder"
        },
        backup: {
          type: "backup",
          text: "版本历史记录",
          icon: "icon-backup"
        },
        currency: [
          {
            type: "to_top",
            text: "上移一层",
            icon: "icon-to_top"
          },
          {
            type: "to_bottom",
            text: "下移一层",
            icon: "icon-to_bottom",
            separator: true
          },
          {
            type: "comment",
            text: "添加评论",
            icon: "icon-comment"
          },
          {
            type: "lock",
            text: "锁定",
            icon: "icon-lock",
            separator: true
          }
        ],
        editOperate: [
          {
            type: "copy",
            text: "复制",
            icon: "icon-copy"
          },
          {
            type: "paste",
            text: "粘贴",
            icon: "icon-paste"
          },
          {
            type: "duplicate",
            text: "副本",
            icon: "icon-duplicate_icon",
            separator: true
          },
          {
            type: "align",
            text: "排列",
            icon: "icon-align",
            separator: true,
            selectClass: "xpp-menu-select-align",
            child: [
              {
                value: "left"
              },
              {
                value: "vertical"
              },
              {
                value: "right"
              },
              {
                value: "top"
              },
              {
                value: "horizontal"
              },
              {
                value: "bottom"
              }
            ]
          },
          {
            type: "delete",
            text: "删除",
            icon: "icon-delete"
          }
        ]
      }
    };
  },
  methods: {
    paperContextMenuEvent({ target }) {
      this.$DiaLoginFoUpDate({ key: "paperContext", value: false });
      this.$UpdataVuexState({ key: "viewMaskShow", data: false });
      this.$emit("menuback", target);
    },
    ...mapMutations(["$UpdataVuexState", "$DiaLoginFoUpDate"])
  }
};
</script>

<style lang="scss">
.xpp-context-menu {
  position: fixed;
  z-index: $view-mask-modal;
  width: 240px;
  padding: 4px 0;
  background-color: #fff;
  font-size: 14px;
  border: 1px solid #dbdee6;
  box-shadow: 0 9px 40px 0 rgba(28, 30, 31, 0.14);

  .xpp-context-menu-item {
    position: relative;
    padding: 0 16px;
    height: 42px;
    color: #8e959a;
    cursor: pointer;
    display: flex;
    align-items: center;
    &:hover {
      background-color: #f8fafe;
    }
    &.context-menu-separator {
      border-bottom: 1px solid #f2f2f2;
    }
  }
  .iconfont {
    color: #c2c6d1;
    font-size: 16px;
    margin-right: 18px;
  }

  span,
  .iconfont {
    flex: none;
    pointer-events: none;
  }
}

.xpp-context-select {
  display: none;
}

.xpp-context-menu-item:hover {
  .xpp-context-select {
    display: flex;
  }
}
.xpp-menu-select-color {
  position: absolute;
  left: 100%;
  top: 0;
  width: 120px;
  padding: 10px 6px;
  font-size: 0;
  background-color: #fff;
  flex-wrap: wrap;
  border: 1px solid #dbdee6;
  box-shadow: 0 9px 40px 0 rgba(28, 30, 31, 0.14);
  .xpp-context-select-item {
    flex: none;
    width: 20px;
    height: 20px;
    margin-right: 4px;
    margin-bottom: 4px;
    box-shadow: inset 0 0 0 2px transparent;
    transition: box-shadow 0.3s;
    border-radius: 3px;
    &:hover {
      box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.1);
    }
  }
  [data-value="transparent"] {
    background: url("/img/color_transpa.png") center no-repeat;
    background-size: cover;
  }
  [data-value="#fff393"] {
    background-color: #fff393;
  }
  [data-value="#ffd342"] {
    background-color: #ffd342;
  }
  [data-value="#ff7d21"] {
    background-color: #ff7d21;
  }
  [data-value="#ff59b6"] {
    background-color: #ff59b6;
  }
  [data-value="#f0ff7e"] {
    background-color: #f0ff7e;
  }
  [data-value="#d9ef34"] {
    background-color: #d9ef34;
  }
  [data-value="#36edc4"] {
    background-color: #36edc4;
  }
  [data-value="#009077"] {
    background-color: #009077;
  }
  [data-value="#ccf6ff"] {
    background-color: #ccf6ff;
  }
  [data-value="#a9e4ff"] {
    background-color: #a9e4ff;
  }
  [data-value="#51b1ff"] {
    background-color: #51b1ff;
  }
  [data-value="#2971ff"] {
    background-color: #2971ff;
  }
  [data-value="#e9f1ff"] {
    background-color: #e9f1ff;
  }
  [data-value="#d9e1ef"] {
    background-color: #d9e1ef;
  }
  [data-value="#4E4E4E"] {
    background-color: #4e4e4e;
  }
  [data-value="#333333"] {
    background-color: #333333;
  }
  [data-value="transparent"] {
    background-color: transparent;
  }
}

.xpp-menu-select-align {
  position: absolute;
  left: 100%;
  top: 0;
  width: 120px;
  padding: 3px;
  font-size: 0;
  background-color: #fff;
  flex-wrap: wrap;
  border: 1px solid #dbdee6;
  box-shadow: 0 9px 40px 0 rgba(28, 30, 31, 0.14);
  .xpp-context-select-item {
    flex: none;
    width: 40px;
    height: 40px;
    background-size: 16px 16px;
    background-repeat: no-repeat;
    background-position: center;
    transition: background-color 0.3s;
    border-radius: 3px;
    &:hover {
      background-color: #f5f7fa;
    }
  }
  [data-value="left"] {
    background-image: url(/img/aligns/left.svg);
  }
  [data-value="vertical"] {
    background-image: url(/img/aligns/vertical.svg);
  }
  [data-value="right"] {
    background-image: url(/img/aligns/right.svg);
  }
  [data-value="top"] {
    background-image: url(/img/aligns/top.svg);
  }
  [data-value="horizontal"] {
    background-image: url(/img/aligns/horizontal.svg);
  }
  [data-value="bottom"] {
    background-image: url(/img/aligns/bottom.svg);
  }
}
</style>
